<template>
  <div class="card">
    <div class="card-header">
      <h1 class="card-header-title">
        Als versandt markieren
      </h1>
    </div>
   
    <div class="card-footer">
      <div class="column">
        <button class="button" type="button" @click="$parent.close()">
          Schließen
        </button>
        &nbsp;
        <b-button
          type="is-success"
          icon-left="delivery"
          @click="saveDelivery()"
          >Als versandt speichern</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InvoiceDelivered",
  props: ["i"],
  data: function() {
    return {
      
      submitted: false,
      error: false
    }
  },
  
  created: function() {},
  methods: {
    async saveDelivery() {
      this.isLoading = true
      var t = this
      t.$http
        .post("/invoices/delivered", {
          invoiceId: t.i.id
        })
        .then(function(resp) {
          t.isLoading = false
          //t.$router.push({ path: "/consignments/view/"+resp.data.data });
          
          t.$parent.$parent.invoice = resp.data.data
          t.$parent.close()
          window.scrollTo(0, 0)

          return true
        })
        .catch(function(error) {
          //console.log('Shithappens')
          if (
            typeof error.response != "undefined" &&
            typeof error.response.data.message != "undefined"
          ) {
            t.error = error.response.data.message
          } else {
            t.error = "Server nicht erreichbar. Bitte versuchen Sie es erneut."
          }
          t.isLoading = false
          return false
        })
    }
  }
}
</script>

<style></style>
