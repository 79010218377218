<template>
  <div>
    <div id="customer" class="panel">
      <h2 class="panel-heading is-4">
        <b-icon icon="contact_mail"></b-icon> Kunde
      </h2>

      <b-table
        v-if="!customerSelected"
        :data="customerList"
        :loading="isLoading"
        :striped="true"
        :hoverable="true"
        icon-pack="icm"
        :mobile-cards="false"
      >
        
          <b-table-column v-slot="props"  field="Customer.id" label="KdNr" numeric sortable>
            {{ $utilities.padLeftWithNull(props.row.id) }}
          </b-table-column>

          <b-table-column v-slot="props"  field="Customer.name" label="Kunde" sortable>
            {{ props.row.name }}
          </b-table-column>

          <b-table-column v-slot="props" 
            field="anzahl"
            label="Abzurechnende Positionen"
            sortable
            numeric
          >
            {{ props.row.anzahl }}
          </b-table-column>

          <b-table-column v-slot="props"  label="Abrechnung erstellen">
            <a
              href="#"
              @click.prevent="selectCustomer(props.row.id, props.index)"
              >Abrechnung für diesen Kunden erstellen</a
            >
          </b-table-column>
       
      </b-table>

      <customer-field
        :customer="customer"
        v-if="customerSelected"
        :changeLink="false"
        class="panel-block"
      />

      <div id="invoicepositions" v-if="customerSelected" class="panel">
        <h2 class="panel-heading is-4">
          <b-icon icon="bottles"></b-icon> Rechnungspositionen
        </h2>
        <invoice-position
          class="panel-block"
          id="ip.key"
          v-for="(ip, index) in invoicePositions"
          :key="ip.index"
          :ip="ip"
          :invoicePositionIndex="index"
          :inputmode="true"
          :consignmentmode="true"
          @position-delete="positionDelete(index)"
        />

        <div v-if="summaries.sum_consignments > 0" class="columns summary">
          <div class="column is-10">Summe Einlieferungen</div>
          <div class="column is-2 has-text-right">
            {{ roundVal(summaries.sum_consignments) }}€
          </div>
        </div>
        <div v-if="summaries.sum_fees > 0" class="columns summary">
          <div class="column is-10">Summe Einlieferungsgebühren</div>
          <div class="column is-2 has-text-right">
            -{{ roundVal(summaries.sum_fees) }}€
          </div>
        </div>

        <div v-if="summaries.total > 0" class="columns total">
          <div class="column is-10">Total</div>
          <div class="column is-2 has-text-right">
            {{ roundVal(summaries.total) }}€
          </div>
        </div>
      </div>

      <div class="panel-block">
        <router-link
          to="/invoices/index"
          custom
          v-slot="{ navigate }"
          type="button"
        >
          <b-button @click="navigate" role="link"
            >Zurück zur Übersicht</b-button
          >
        </router-link>
        &nbsp;
        <b-button
          @click="saveInvoice()"
          v-if="customerSelected && invoicePositions.length >= 1"
          class="button"
          type="is-info"
          icon-left="cloud_upload"
          native-type="submit"
        >
          Speichern
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import CustomerField from "../_helpers/customerfield.vue"
import InvoicePosition from "../_helpers/invoiceposition.vue"
export default {
  name: "invoiceForm",
  components: {
    CustomerField,
    InvoicePosition
  },

  data() {
    return {
      error: "",
      isLoading: true,
      customerList: [],
      customer: {},
      customerSelected: false,
      summaries: {
        sum_consignments: 0,
        sum_fees: 0,
        total: 0
      },
      noOfInvoicePositions: 0,
      invoicePositions: []
    }
  },

  created: function() {
    this.$store.commit("setHeadline", {
      subheadline: "Einlieferungsabrechnung erstellen"
    })
    this.getCustomers()
  },
  methods: {
    getCustomers: async function() {
      this.isLoading = true
      var t = this
      t.$http
        .get("/invoices/getconsignmentcustomers")

        .then(function(resp) {
          t.customerList = resp.data.data
          t.isLoading = false
          return true
        })
        .catch(function(error) {
          //console.log(error)
          if (
            typeof error.response != "undefined" &&
            typeof error.response.data.message != "undefined"
          ) {
            t.error = error.response.data.message
          } else {
            t.error = "Server nicht erreichbar. Bitte versuchen Sie es erneut."
          }
        })
    },
    selectCustomer: function(customerId, index) {
      if (
        typeof this.customerList[index] == "object" &&
        this.customerList[index].id == customerId
      ) {
        this.customer = this.customerList[index]
        this.customerSelected = true
        this.getInvoicePositions(this.customer.id)
        this.noOfInvoicePositions = 0
        this.invoicePositions = []
      }
    },
    getInvoicePositions: function() {
      this.isLoading = true
      var t = this
      t.$http
        .get("/invoices/getconsignmentdata/" + t.customer.id)

        .then(function(resp) {
          t.invoicePositions = resp.data.data
          t.noOfInvoicePositions = t.invoicePositions.length
          t.isLoading = false
          t.recalcInvoice()
          return true
        })
        .catch(function(error) {
          //console.log(error)
          if (
            typeof error.response != "undefined" &&
            typeof error.response.data.message != "undefined"
          ) {
            t.error = error.response.data.message
          } else {
            t.error = "Server nicht erreichbar. Bitte versuchen Sie es erneut."
          }
        })
    },
    async saveInvoice() {
      this.isLoading = true
      var t = this
      t.$http
        .post("/invoices/save", {
          type: "post",
          customer: t.customer,
          invoicePositions: t.invoicePositions,
          summaries: t.summaries
        })
        .then(function(resp) {
          t.isLoading = false
          //Daten in Eltern setzen
         
          //Auf Ansicht umleiten
          t.$router.push({ path: "/invoices/view/" + resp.data.data.id })
          return true
        })
        .catch(function(error) {
          //console.log('Shithappens')
          if (
            typeof error.response != "undefined" &&
            typeof error.response.data.message != "undefined"
          ) {
            t.error = error.response.data.message
          } else {
            t.error = "Server nicht erreichbar. Bitte versuchen Sie es erneut."
          }
          t.isLoading = false
          return false
        })
    },
    positionDelete(index) {
      //console.log("Läsche index " + index)
      this.invoicePositions[index].id = "deleted"
      this.recalcInvoice()
    },
    recalcInvoice: function() {
      var t = this
      //Berechne neu
      var sum_consignments = 0
      var sum_fees = 0
      //console.log(t.invoicePositions)

      for (var i = 0; i < t.invoicePositions.length; i++) {
        //console.log(i)
        sum_consignments += parseFloat(t.invoicePositions[i].price)
        sum_fees += parseFloat(t.invoicePositions[i].fee_absolute)
      }
      this.summaries.sum_consignments = sum_consignments
      this.summaries.sum_fees = this.roundVal(sum_fees)
      this.summaries.total =
        parseFloat(this.summaries.sum_consignments) -
        parseFloat(this.summaries.sum_fees)
      //console.log("fertsch 1")
    },
    roundVal: function(val, digits = 2) {
      return (Math.round(val * 100) / 100).toFixed(digits)
    }
  }
}
</script>
<style>
div.columns.summary {
  margin-left: 15px;
  margin-right: 15px;
  border-bottom: 1px solid #999999;
}
div.columns.total {
  margin-left: 15px;
  margin-right: 15px;
  font-size: 1.1rem;
  font-weight: 700;
}
</style>
