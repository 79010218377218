<template>
  <b-table
    :data="data.data"
    :loading="isLoading"
    :striped="true"
    :hoverable="true"
    :range-before="5"
    :range-after="5"
    paginated
    backend-pagination
    pagination-position="both"
    :total="data.count"
    :per-page="data.perpage"
    @page-change="onPageChange"
    :current-page.sync="data.page"
    aria-next-label="Weiter"
    aria-previous-label="Zurück"
    aria-page-label=" Seite "
    aria-current-label="Aktuelle Seite"
    backend-sorting
    :default-sort="[data.sortField, data.sortOrder]"
    @sort="onSort"
    icon-pack="icm"
    :mobile-cards="false"
    :row-cell="(row, index) => getStatusColor(row.status_code)"
  >
    <b-table-column
      v-slot="props"
      field="Invoice.id"
      label="Id"
      cell-class="status nobreak"
      numeric
      sortable
    >
      {{ props.row.type == "proforma" ? "PF" : "RE" }}{{ $utilities.padLeftWithNull(props.row.doc_id) }}
    </b-table-column>

    <b-table-column
      v-slot="props"
      field="fcreated_at"
      label="Datum"
      numeric
      sortable
    >
      {{ props.row.fcreated_at }}
    </b-table-column>

    <b-table-column
      v-slot="props"
      field="Invoice.customer_id"
      label="Kunde"
      sortable
    >
      #{{ $utilities.padLeftWithNull(props.row.customer_id) }}
      {{ props.row.name }}
    </b-table-column>

    <b-table-column
      v-slot="props"
      field="Invoice.total"
      label="Rechnungssumme"
      numeric
      sortable
    >
      {{ nf.format(props.row.total) }}€
    </b-table-column>

    <b-table-column
      v-slot="props"
      field="Invoice.vat_absolute"
      label="MwSt."
      numeric
      sortable
    >
      {{ nf.format(props.row.vat_absolute) }}€
    </b-table-column>

    <b-table-column v-slot="props" label="Rechnungsstatus" class="nobreak">
      <b-tag type="is-danger" size="is-small" v-if="props.row.cancelled >= 1"
        >storn.</b-tag
      >
      <b-icon
        v-if="props.row.cancelled == 0"
        icon="dollar"
        :class="{
          green: props.row.paid > 1,
          grey: props.row.paid == 0 || props.row.paid == null
        }"
        title="bezahlt"
        style="padding-right: 5px"
      >
      </b-icon>

      <b-icon
        v-if="
          props.row.sum_wines >= 1 &&
          props.row.cancelled == 0 &&
          props.row.type == 'invoice'
        "
        icon="delivery"
        :class="{
          green: props.row.delivered > 1,
          grey: props.row.delivered == 0 || props.row.delivered == null
        }"
        title="versandt"
      ></b-icon>
    </b-table-column>

    <b-table-column
      v-slot="props"
      label="Ans."
      class="iconlink"
      header-class="iconlink"
    >
      <a
        :href="`/invoices/view/${props.row.id}`"
        title="Ansehen"
        @click.prevent="invoiceView(props.row.id)"
      >
        <b-icon icon="find_in_page"></b-icon>
      </a>
    </b-table-column>
    <b-table-column
      v-slot="props"
      label="RE"
      class="iconlink"
      header-class="iconlink"
    >
      <a
        title="Rechnung (PDF)"
        @click.prevent="downloadInvoicePDF(props.row.id, props.row.doc_id)"
      >
        <b-icon icon="file-pdf"></b-icon>
      </a>
    </b-table-column>
    <b-table-column
      v-slot="props"
      label="LS"
      class="iconlink"
      header-class="iconlink"
    >
      <a
        v-if="showDeliveryPdfLink(props.row.article)"
        title="Lieferschein (PDF)"
        @click.prevent="downloadDeliveryPDF(props.row.id, props.row.doc_id)"
      >
        <b-icon icon="package-variant-closed"></b-icon>
      </a>
    </b-table-column>
  </b-table>
</template>

<script>
export default {
  name: "invoiceList",
  data() {
    return {
      nf: Intl.NumberFormat(),
      data: {
        count: 0,
        perpage: 100,
        data: [],
        page: 1,
        order: {
          field: "Invoice.id",
          direction: "DESC"
        }
      },
      error: "",
      subtitle: "",
      isLoading: true,
      searchphrase: "",
      modal: false
    }
  },

  created: function () {
    this.$store.commit("setHeadline", {
      subheadline: "Übersicht"
    })
    this.invoiceList()
  },
  methods: {
    onPageChange(page) {
      this.data.page = page
      this.invoiceList()
    },
    /*
     * Handle sort event
     */
    onSort(field, direction) {
      //console.log(field + ' » ' + direction)
      this.data.sortField = field
      this.data.sortOrder = direction
      this.data.order = { field, direction }
      this.invoiceList()
    },
    search: function (searchphrase) {
      this.searchphrase = searchphrase
      this.invoiceList()
    },
    invoiceList: async function (data = null) {
      //console.log("invoices list")
      this.isLoading = true
      if (data != null) {
        //console.log('Daten übergeben')
        this.data = data
        this.isLoading = false
      } else {
        var t = this
        t.$http
          .post("/invoices", {
            page: t.data.page,
            search: t.$parent.searchphrase,
            order: t.data.order
          })

          .then(function (resp) {
            t.data = resp.data.data
            t.isLoading = false
            return true
          })
          .catch(function (error) {
            //console.log(error)
            if (
              typeof error.response != "undefined" &&
              typeof error.response.data.message != "undefined"
            ) {
              t.error = error.response.data.message
            } else {
              t.error =
                "Server nicht erreichbar. Bitte versuchen Sie es erneut."
            }
          })
      }
    },
    downloadInvoicePDF: function (id, docId) {
      var url =
        this.$store.getters.config.page.apiBaseUrl +
        "/invoices/pdf-invoice/" +
        id
      this.$http
        .get(url, { responseType: "blob" })
        .then((response) => {
          const blob = new Blob([response.data], { type: "application/pdf" })
          const link = document.createElement("a")
          link.href = URL.createObjectURL(blob)
          link.download = "invoice_" + docId + ".pdf"
          link.click()
          URL.revokeObjectURL(link.href)
        })
        .catch(console.error)
    },

    downloadDeliveryPDF: function (id, docId) {
      var url =
        this.$store.getters.config.page.apiBaseUrl +
        "/invoices/pdf-delivery/" +
        id
      this.$http
        .get(url, { responseType: "blob" })
        .then((response) => {
          const blob = new Blob([response.data], { type: "application/pdf" })
          const link = document.createElement("a")
          link.href = URL.createObjectURL(blob)
          link.download = "delivery_" + docId + ".pdf"
          link.click()
          URL.revokeObjectURL(link.href)
        })
        .catch(console.error)
    },

    invoiceView: function (id) {
      this.$router
        .push({
          path: "/invoices/view/" + id
        })
        .catch((err) => {})
    },
    getStatusColor: function (code) {
      switch (code) {
        case "9":
          return "red"
        case "4":
          return "green"
        case "3":
          return "lightgreen"
        case "2":
          return "blue"
        case "1":
          return "yellow"
        default:
          return ""

        /*

        (case 
        when ((`delivered` = 1) and (`sold` = 0)) then 9 
        when ((`delivered` = 1) and (`sold` = 1)) then 4 
        when ((`delivered` = 0) and (`sold` = 1)) then 3 
        when ((`delivered` = 0) and (`sold` = 0) and (`auction_id` >= 1)) then 2 
        when ((`delivered` = 0) and (`sold` = 0) and (`auction_id` = 0)) then 1 
        else 0 end)
        */
      }
    },
    showDeliveryPdfLink (article) {
      if (article.length >= 1)
      {
        for (var i = 0; i < article.length; i++)
        {
          if (article[i].type == 'wine')
          {
            return true;
          }
        }
      }
      return false;
    }
  }
}
</script>