import { render, staticRenderFns } from "./add.vue?vue&type=template&id=1d502906"
import script from "./add.vue?vue&type=script&lang=js"
export * from "./add.vue?vue&type=script&lang=js"
import style0 from "./add.vue?vue&type=style&index=0&id=1d502906&prod&lang=css"


/* normalize component */
import normalizer from "!../../../.yarn/__virtual__/vue-loader-virtual-204c11ec9d/0/cache/vue-loader-npm-15.11.1-829412283a-509a816d45.zip/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports