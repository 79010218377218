<template>
  <div class="card">
    <div class="card-header">
      <h1 class="card-header-title">
        Rechnung bezahlt
      </h1>
    </div>
    <div class="card-content">
      <label
        >Bitte geben Sie die Zahlungsmethode an.</label
      >
      <b-field label="Typ" >
            <b-select v-model.number="paymentType" >
              <option
                v-for="option in $utilities.getList('paymentTypes')"
                :value="option.id" :key="option.id">
                {{ option.name }}
              </option>
            </b-select>
          </b-field>
    </div>
    <div class="card-footer">
      <div class="column">
        <button class="button" type="button" @click="$parent.close()">
          Schließen
        </button>
        &nbsp;
        <b-button
          v-if="readyToSave"
          type="is-success"
          icon-left="dollar"
          @click="savePayment()"
          >Zahlung speichern</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "InvoicePaid",
  props: ["i"],
  data: function() {
    return {
      paymentType: null,
      submitted: false,
      error: false
    }
  },
  computed: {
    readyToSave: function() {
      if (this.paymentType != null) {
        return true
      }
      return false
    }
  },
  created: function() {},
  methods: {
    async savePayment() {
      this.isLoading = true
      var t = this
      t.$http
        .post("/invoices/paid", {
          invoiceId: t.i.id,
          paymentType: t.paymentType
        })
        .then(function(resp) {
          t.isLoading = false
          //t.$router.push({ path: "/consignments/view/"+resp.data.data });
          
          t.$parent.$parent.invoice = resp.data.data
          t.$parent.close()
          window.scrollTo(0, 0)

          return true
        })
        .catch(function(error) {
          //console.log('Shithappens')
          if (
            typeof error.response != "undefined" &&
            typeof error.response.data.message != "undefined"
          ) {
            t.error = error.response.data.message
          } else {
            t.error = "Server nicht erreichbar. Bitte versuchen Sie es erneut."
          }
          t.isLoading = false
          return false
        })
    }
  }
}
</script>

<style></style>
