<template>
  <div class="card">
    <div class="card-content">
      <div class="is-half column">
        <b-field label="Datumsbereich">
          <b-datepicker
            v-model="dateRange"
            locale="de-DE"
            placeholder="Bitte wählen Sie den Datumsbereich aus"
            icon="calendar"
            range
            trap-focus
          >
          </b-datepicker>
        </b-field>

        <b-button
          class="button"
          type="is-info"
          native-type="submit"
          icon-left="cloud_download"
          @click="getCsvExport()"
          >CSV-Datei generieren</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "csvExportForm",

  data() {
    return {
      error: "",
      isLoading: true,
      dateRange: null
    }
  },

  created: function() {
    //console.log("hurz")
    this.$store.commit("setHeadline", {
      subheadline: "Steuerberater-Export (CSV)"
    })
  },
  methods: {
    getCsvExport: function() {
      var url =
        this.$store.getters.config.page.apiBaseUrl + "/invoices/getcsvexport/"
      this.$http
        .post(url, { dateRange: this.dateRange }, { responseType: "blob" })
        .then((response) => {
          const blob = new Blob([response.data], { type: "application/xlsx" })
          const link = document.createElement("a")
          link.href = URL.createObjectURL(blob)
          link.download = "steuerberater" + ".csv"
          link.click()
          URL.revokeObjectURL(link.href)
        })
        .catch(console.error)
    }
  }
}
</script>
<style></style>
