<template>
  <div class="card">
    <div class="card-header">
      <h1 class="card-header-title">
        UPS-Label
      </h1>
    </div>

    <div class="card-content">
      <div v-if="i.ups_number == null && currentStep == 1">
        <b-button type="is-success" icon-left="ups" @click="requestPrice()"
          >UPS-Preis anfragen</b-button
        >
      </div>
      <div v-if="i.ups_number == null && currentStep == 2">
        <div class="message is-success message-body"><b>ShipmentNumber: {{ ups.number }}<br />
        Kosten: {{ ups.rate }} €</b></div>
        <div v-if="typeof ups.packageinfo != 'undefined' && ups.packageinfo.length >= 1" v-for="(p, index) in ups.packageinfo" class="message message-body" :key="index">
          <b>Paket {{index + 1}}</b><br>
          Pakettyp: {{p.size.qty+'er' }} ({{p.size.length }} x {{p.size.width }} x {{p.size.height }} cm)<br />
          Gewicht: {{p.details.weight}}KG<br />
          Versicherter Wert: {{p.details.value}} €<br />
          Zugeordnete Flaschen: {{p.details.reference}}

        </div>
        <b-button type="is-success" icon-left="ups" @click="acceptPrice()"
          >Akzeptieren</b-button
        >
        <b-button type="is-danger" icon-left="cancel" @click="$parent.close()"
          >Verwerfen</b-button
        >
      </div>
      <div v-if="i.ups_number != null && i.ups_number.length > 10">
        <div class="message is-success message-body"><b>ShipmentNumber: {{ ups.number }}<br />
        Kosten: {{ ups.rate }} € </b></div>
        <div v-if="typeof ups.packageinfo != 'undefined' && ups.packageinfo.length >= 1" v-for="(p, index) in ups.packageinfo" class="message message-body" :key="index">
          <b>Paket {{index + 1}}</b><br>
          Pakettyp: {{p.size.qty+'er' }} ({{p.size.length }} x {{p.size.width }} x {{p.size.height }} cm)<br />
          Gewicht: {{p.details.weight}}KG<br />
          Versicherter Wert: {{p.details.value}} €<br />
          Zugeordnete Flaschen: {{p.details.reference}}

        </div>
        <b-button type="is-success" icon-left="file-pdf" @click="printLabel()"
          >Label drucken</b-button
        >
        <b-button type="is-danger" icon-left="cancel" @click="voidLabel()"
          >Stornieren</b-button
        >
      </div>
      <b-loading
        :is-full-page="false"
        :active.sync="isLoading"
        :can-cancel="false"
      ></b-loading>
    </div>

    <div class="card-footer">
      <div class="column">
        <button class="button" type="button" @click="$parent.close()">
          Schließen
        </button>
        &nbsp;
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CreateUpsLabel",
  props: ["i"],
  data: function() {
    return {
      isLoading: false,
      submitted: false,
      error: false,
      currentStep: 1,
      ups: {}
    }
  },

  created: function() {
    if (this.i.ups_number != null && this.i.ups_number.length > 10) {
      this.currentStep = 0
    }
    if(this.i.ups_packageinfo != null && this.i.ups_packageinfo.length > 10)
    {
      this.ups = JSON.parse(this.i.ups_packageinfo);
    }
  },
  methods: {
    async requestPrice() {
      this.isLoading = true
      var t = this
      t.$http
        .post("/ups/confirmlabel", {
          invoiceId: t.i.id
        })
        .then(function(resp) {
          if (
            typeof resp.data.data.ShipmentIdentificationNumber != "undefined"
          ) {
            t.ups.number = resp.data.data.ShipmentIdentificationNumber
            t.ups.digest = resp.data.data.ShipmentDigest
            t.ups.rate =
              resp.data.data.NegotiatedRates.NetSummaryCharges.GrandTotal.MonetaryValue
              
            if (typeof resp.data.packageinfo != "undefined") {
              t.ups.packageinfo = resp.data.packageinfo
            }
            t.currentStep = 2
          }

          t.isLoading = false
          //t.$router.push({ path: "/consignments/view/"+resp.data.data });

          return true
        })
        .catch(function(error) {
          //console.log('Shithappens')
          if (
            typeof error.response != "undefined" &&
            typeof error.response.data.message != "undefined"
          ) {
            t.error = error.response.data.message
          } else {
            t.error = "Server nicht erreichbar. Bitte versuchen Sie es erneut."
          }
          t.isLoading = false
          return false
        })
    },
    async acceptPrice() {
      this.isLoading = true
      var t = this
      t.$http
        .post("/ups/acceptlabel", {
          invoiceId: t.i.id,
          ups: t.ups
        })
        .then(function(resp) {
          if (
            typeof resp.data.data.Response.ResponseStatusCode != "undefined" &&
            resp.data.data.Response.ResponseStatusCode == 1
          ) {
            t.i.ups_number =
              resp.data.data.ShipmentResults.ShipmentIdentificationNumber
            //t.$parent.$parent.invoice.ups_number = resp.data.data.ShipementResult.ShipmentIdentificationNumber;

            t.currentStep = 0
          }

          if (typeof resp.data.newInvoiceData != "undefined") {
            t.$parent.$parent.invoice = resp.data.newInvoiceData
          }

          t.isLoading = false
          //t.$router.push({ path: "/consignments/view/"+resp.data.data });

          return true
        })
        .catch(function(error) {
          //console.log('Shithappens')
          if (
            typeof error.response != "undefined" &&
            typeof error.response.data.message != "undefined"
          ) {
            t.error = error.response.data.message
          } else {
            t.error = "Server nicht erreichbar. Bitte versuchen Sie es erneut."
          }
          t.isLoading = false
          return false
        })
    },
    async voidLabel() {
      this.isLoading = true
      var t = this
      t.$http
        .post("/ups/voidlabel", {
          invoiceId: t.i.id,
          upsNumber: t.ups.number
        })
        .then(function(resp) {
          if (
            typeof resp.data.data.Status.StatusType.Code != "undefined" &&
            resp.data.data.Status.StatusType.Code == 1
          ) {
            t.i.ups_number = null
            t.currentStep = 1
          }
         if (typeof resp.data.newInvoiceData != "undefined") {
            t.$parent.$parent.invoice = resp.data.newInvoiceData
          }

          t.isLoading = false
          //t.$router.push({ path: "/consignments/view/"+resp.data.data });

          return true
        })
        .catch(function(error) {
          //console.log('Shithappens')
          if (
            typeof error.response != "undefined" &&
            typeof error.response.data.message != "undefined"
          ) {
            t.error = error.response.data.message
          } else {
            t.error = "Server nicht erreichbar. Bitte versuchen Sie es erneut."
          }
          t.isLoading = false
          return false
        })
    },
    async printLabel() {
      var t = this
      var url = this.$store.getters.config.page.apiBaseUrl + "/ups/printlabel/"
      this.$http
        .post(url, { upsNumber: t.i.ups_number }, { responseType: "blob" })
        .then((response) => {
          const blob = new Blob([response.data], { type: "application/pdf" })
          const link = document.createElement("a")
          link.href = URL.createObjectURL(blob)
          link.download = "ups_label_" + t.i.ups_number + ".pdf"
          link.click()
          URL.revokeObjectURL(link.href)
        })
        .catch(console.error)
    }
  }
}
</script>

<style></style>
