<template>
  <div>
    <div id="type" class="panel">
      <h2 class="panel-heading is-4">
        <b-icon icon="document-check"></b-icon> Rechnungstyp
      </h2>
      <div class="p-3">
        <label>Rechnungstyp: </label>
        {{ invoice.type == "proforma" ? "ProForma-Rechnung" : "Rechnung" }}
      </div>

      <div class="pl-3 pb-3">
        <label>Mehrwertsteuer: </label>
        {{ invoice.vat_percent >= 1 ? "berechnet" : "nicht berechnet" }}
      </div>
    </div>
    <div id="customer" class="panel">
      <h2 class="panel-heading"><b-icon icon="contact_mail"></b-icon> Kunde</h2>
      <customer-field
        v-if="!isLoading"
        :customer="JSON.parse(invoice.adress)"
        :changeLink="false"
        class="panel-block"
      />
    </div>

    <div id="status" class="panel">
      <h2 class="panel-heading"><b-icon icon="info"></b-icon> Status</h2>

      <div class="columns panel-block">
        <div class="column">
          <label>Rechnung</label>
          <div>
            <b-button
              icon-left="file-pdf"
              size="is-small"
              type="is-info"
              @click="downloadInvoicePDF(invoice.id, invoice.doc_id)"
              >Rechnung</b-button
            >
          </div>
        </div>
        <div class="column" v-if="needsTaxInvoice()">
          <label>Steuer</label>
          <div>
            <b-button
              icon-left="section"
              size="is-small"
              type="is-info"
              @click="downloadTaxInvoicePDF(invoice.id, invoice.doc_id)"
              >Steuer</b-button
            >
          </div>
        </div>
        <div class="column" v-if="invoice.type == 'invoice'">
          <label>Lieferschein</label>
          <div>
            <b-button
              v-if="showDeliveryPdfLink"
              icon-left="package-variant-closed"
              size="is-small"
              type="is-info"
              @click="downloadDeliveryPDF(invoice.id, invoice.doc_id)"
              >Lieferschein</b-button
            >
          </div>
        </div>
        <div class="column" v-if="invoice.type == 'invoice'">
          <label>Packliste</label>
          <div>
            <b-button
              v-if="showDeliveryPdfLink"
              icon-left="package-variant-closed"
              size="is-small"
              type="is-info"
              @click="downloadPacklistPDF(invoice.id, invoice.doc_id)"
              >Packliste</b-button
            >
          </div>
        </div>
        <div class="column">
          <label>Erstellt</label>
          <div>
            <span> {{ $utilities.formatUnixtimeToDmYHi(invoice.created_at) }}</span>
          </div>
        </div>

        <div class="column">
          <label>Bezahlt</label>
          <div>
            <span v-if="invoice.paid > 1">
              {{ $utilities.formatUnixtimeToDmYHi(invoice.paid) }}</span
            >
            <b-button
              v-if="(invoice.paid == 0 || invoice.paid == null) && invoice.cancelled == 0"
              icon-left="dollar"
              size="is-small"
              @click="setPaid()"
              type="is-success"
              >Als bezahlt markieren</b-button
            >
          </div>
        </div>

        <div class="column" v-if="invoice.type == 'invoice'">
          <label>Ausgeliefert</label>
          <div>
            <span v-if="invoice.delivered > 1">
              {{ $utilities.formatUnixtimeToDmYHi(invoice.delivered) }}</span
            >
            <b-button
              v-if="
                (invoice.delivered == 0 || invoice.delivered == null) &&
                invoice.cancelled == 0 &&
                invoice.sum_wines > 1
              "
              icon-left="delivery"
              size="is-small"
              @click="setDelivery()"
              type="is-success"
              >Als geliefert markieren</b-button
            >
          </div>
        </div>

        <div class="column">
          <label>Storniert am</label>
          <div>
            <a v-if="invoice.cancelled > 1">
              {{ $utilities.formatUnixtimeToDmYHi(invoice.cancelled) }}</a
            >
            <b-button
              v-if="invoice.cancelled == 0 || invoice.cancelled == null"
              icon-left="cancel"
              size="is-small"
              @click="cancel1()"
              type="is-danger"
              >stornieren</b-button
            >
          </div>
        </div>
      </div>
    </div>

    <div id="invoicepositions" class="panel">
      <h2 class="panel-heading"><b-icon icon="bottles"></b-icon> Rechnungspositionen</h2>

      <div class="columns summary" v-for="(ip, index) in invoice.article" :key="index">
        <div class="column is-9">
          <div>{{ ip.title }} ({{ getType(ip.type) }})</div>
          <!--- Alte Rechnung, altes schema -->
          <div
            v-if="
              ip.type == 'wine' && invoice.type == 'invoice' && ip.stockArticle != null
            "
            class="smallgrey2"
          >
            <b>EK-Preis {{ nf.format(ip.stockArticle.price_ek) }}€/Flasche</b>, Lager-ID:
            <router-link :to="'/stocks/view/' + ip.stockArticle.id"
              >#{{ $utilities.padLeftWithNull(ip.stockArticle.id) }}</router-link
            >

            <br />
            <span class="has-text-danger">{{
              ip.from_private == 1 ? "differenzbesteuert, " : ""
            }}</span>

            W{{ ip.stockArticle.wine_id }}-A{{ ip.stockArticle.article_id }},
          </div>

          <!--- Alte Rechnung, neues schema -->
          <div
            v-if="
              ip.type == 'wine' && invoice.type == 'invoice' && ip.stockArticles != null
            "
            class="smallgrey2"
          >
            <span class="smallgrey2">
              <b>
                W{{ ip.article.wine_id }}-A{{ ip.article_id }}

                <span class="has-text-danger">{{
                  ip.from_private == 1 ? ", differenzbesteuert " : ""
                }}</span> </b
              ><br />
              {{ getStockArticleDetails(ip.stockArticles) }}
            </span>
          </div>
        </div>

        <div class="column is-1 has-text-right">
          <label v-if="ip.type == 'wine'">Fl.</label>
          <div v-if="ip.type == 'wine'">{{ ip.bottles }}</div>
        </div>
        <div class="column is-2 has-text-right">
          <label>Betrag/Preis</label>
          <div>{{ ip.price }}€</div>
        </div>
      </div>

      <div v-if="invoice.sum_wines > 0" class="columns summary">
        <div class="column is-10">Summe Weine</div>
        <div class="column is-2 has-text-right">
          {{ nf.format(roundVal(invoice.sum_wines)) }}€
        </div>
      </div>

      <div v-if="invoice.sum_misc > 0" class="columns summary">
        <div class="column is-10">Summe Sonstiges</div>
        <div class="column is-2 has-text-right">{{ invoice.sum_misc }}€</div>
      </div>
      <div v-if="invoice.vat_absolute > 0" class="columns summary">
        <div class="column is-10">
          {{ invoice.vat_percent }}% Mwst. auf
          {{
            nf.format(
              roundVal(
                parseFloat(invoice.sum_misc) + parseFloat(invoice.sum_wines_for_vat)
              )
            )
          }}€
        </div>
        <div class="column is-2 has-text-right">
          {{ nf.format(roundVal(invoice.vat_absolute)) }}€
        </div>
      </div>
      <div v-if="invoice.total > 0" class="columns total">
        <div class="column is-10">Total</div>
        <div class="column is-2 has-text-right">
          {{ nf.format(roundVal(invoice.total)) }}€
        </div>
      </div>
    </div>

    <div id="history" class="panel">
      <h2 class="panel-heading is-4"><b-icon icon="history"></b-icon> History</h2>
      <history v-if="invoice.id" :history="invoice.history" class="card-content" />
    </div>

    <div class="panel-block">
      <router-link
        to="/invoices"
        class="button"
        type="button"
        custom
        v-slot="{ navigate }"
      >
        <button @click="navigate" role="link" class="button">
          Zurück zur Übersicht
        </button></router-link
      >
    </div>

    <b-loading
      :is-full-page="false"
      :active.sync="isLoading"
      :can-cancel="false"
    ></b-loading>
  </div>
</template>

<script>
import CustomerField from "../_helpers/customerfield.vue";
import History from "../_helpers/history.vue";
import CancelInvoice from "../_helpers/cancelinvoice.vue";
import CreateUpsLabel from "../_helpers/createupslabel.vue";
import InvoicePaid from "../_helpers/invoicepaid.vue";
import InvoiceDelivered from "../_helpers/invoicedelivered.vue";
export default {
  name: "invoiceView",
  components: { CustomerField, History },

  data: function () {
    return {
      nf: Intl.NumberFormat(),
      check: 4,
      isLoading: true,
      invoice: {},
      error: false,
      isLoading: true,
      showDeliveryPdfLink: false,
    };
  },
  created: function () {
    this.pageView(this.$route.params.id);
    var t = this;
    this.$store.commit("setHeadline", {
      subheadline: "Rechnung ansehen",
    });
  },

  methods: {
    pageView: function (id) {
      var t = this;
      t.$http({
        url: "/invoices/view/" + id,
        method: "get",
      })
        .then(function (resp) {
          t.invoice = resp.data.data;
          t.isLoading = false;
          t.$store.commit("setHeadline", {
            subheadline:
              "Rechnung ansehen #" + t.$utilities.padLeftWithNull(t.invoice.doc_id),
          });
          for (var i = 0; i < t.invoice.article.length; i++) {
            if (t.invoice.article[i].type == "wine") {
              t.showDeliveryPdfLink = true;
              break;
            }
          }

          return true;
        })
        .catch(function (error) {
          //console.log(error)
          if (
            typeof error.response != "undefined" &&
            typeof error.response.data.message != "undefined"
          ) {
            t.error = error.response.data.message;
          } else {
            t.error = "Server nicht erreichbar. Bitte versuchen Sie es erneut.";
          }
        });
    },
    roundVal: function (val, digits = 2) {
      return (Math.round(val * 100) / 100).toFixed(digits);
    },
    getType: function (value) {
      var list = this.$utilities.invoicePositionTypes;
      for (var i = 0; i < list.length; i++) {
        if (list[i].id == value) {
          return list[i].name;
        }
      }
      return "unbekannte Rechnungsposition";
    },

    cancel1(id) {
      var t = this;
      t.$buefy.modal.open({
        parent: t,
        component: CancelInvoice,
        hasModalCard: false,
        width: 960,
        props: { i: this.invoice },
      });
    },
    setPaid(id, title) {
      var t = this;
      t.$buefy.modal.open({
        parent: t,
        component: InvoicePaid,
        hasModalCard: false,
        width: 960,
        props: { i: this.invoice },
      });
    },
    setDelivery(id, title) {
      var t = this;
      t.$buefy.modal.open({
        parent: t,
        component: InvoiceDelivered,
        hasModalCard: false,
        width: 960,
        props: { i: this.invoice },
      });
    },
    createUpsLabel() {
      var t = this;
      t.$buefy.modal.open({
        parent: t,
        component: CreateUpsLabel,
        hasModalCard: false,
        width: 960,
        props: { i: this.invoice },
      });
    },
    downloadInvoicePDF: function (id, docId) {
      var url =
        this.$store.getters.config.page.apiBaseUrl + "/invoices/pdf-invoice/" + id;
      this.$http
        .get(url, { responseType: "blob" })
        .then((response) => {
          const blob = new Blob([response.data], { type: "application/pdf" });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = "invoice_" + docId + ".pdf";
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(console.error);
    },

    downloadTaxInvoicePDF: function (id, docId) {
      var url =
        this.$store.getters.config.page.apiBaseUrl + "/invoices/pdf-tax-invoice/" + id;
      this.$http
        .get(url, { responseType: "blob" })
        .then((response) => {
          const blob = new Blob([response.data], { type: "application/pdf" });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = "tax_invoice_" + docId + ".pdf";
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(console.error);
    },

    downloadDeliveryPDF: function (id, docId) {
      var url =
        this.$store.getters.config.page.apiBaseUrl + "/invoices/pdf-delivery/" + id;
      this.$http
        .get(url, { responseType: "blob" })
        .then((response) => {
          const blob = new Blob([response.data], { type: "application/pdf" });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = "delivery_" + docId + ".pdf";
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(console.error);
    },
    downloadPacklistPDF: function (id, docId) {
      var url =
        this.$store.getters.config.page.apiBaseUrl + "/invoices/pdf-packlist/" + id;
      this.$http
        .get(url, { responseType: "blob" })
        .then((response) => {
          const blob = new Blob([response.data], { type: "application/pdf" });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = "packlist_" + docId + ".pdf";
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(console.error);
    },
    getConsignmentDetails: function (d) {
      var data = JSON.parse(d);
      var h = [];
      for (var i = 0; i < data.length; i++) {
        h.push(
          "Einlieferung " +
            data[i].consignment +
            ", ID " +
            this.$utilities.padLeftWithNull(data[i].id) +
            ", " +
            data[i].bottles +
            " Flaschen, " +
            data[i].article +
            "</div>"
        );
      }

      return h.join("<br />");
    },
    getStockArticleDetails(sa) {
      var d = [];
      for (var i = 0; i < sa.length; i++) {
        if (sa[i].bottles >= 1) {
          d.push(
            "Lager-ID " +
              sa[i].stock_article_id +
              ": " +
              sa[i].bottles +
              " Fl. je " +
              this.nf.format(sa[i].price_ek) +
              "€"
          );
        }
      }

      return d.join(", ");
    },

    needsTaxInvoice () {
      if (typeof this.invoice.article == 'object')
      {
        for (var i = 0; i < this.invoice.article.length; i++) {
          if (this.invoice.article[i].from_private == 1) {
            return true;
          }
        }
      }
      return false;
    },
  },
};
</script>
<style>
div.columns.summary {
  margin-left: 15px;
  margin-right: 15px;
  border-bottom: 1px solid #999999;
}
div.columns.total {
  margin-left: 15px;
  margin-right: 15px;
  font-size: 1.1rem;
  font-weight: 700;
}
</style>
