<template>
  <div>
    <div id="type" class="panel">
      <h2 class="panel-heading is-4">
        <b-icon icon="document-check"></b-icon> Rechnungstyp
      </h2>
      <div v-if="!customerSelected" class="p-3">
        <label>Rechnungstyp: </label>
        <b-radio v-model="invoiceType" name="Rechnugsart" native-value="invoice" default>
          Rechnung
        </b-radio>
        <b-radio v-model="invoiceType" name="language" native-value="proforma">
          Pro-Forma
        </b-radio>
      </div>
      <div v-if="customerSelected" class="pl-3 pt-3">
        <label>Rechnungstyp: </label>
        {{ invoiceType == "proforma" ? "ProForma-Rechnung" : "Rechnung" }}
      </div>
      <div v-if="customerSelected" class="pl-3 pb-3">
        <label>Mehrwertsteuer: </label>
        {{ calculateWithVat == 1 ? "wird berechnet" : "wird nicht berechnet" }}
        ({{ explanationForVat }})
      </div>
    </div>

    <div id="customer" class="panel">
      <h2 class="panel-heading is-4"><b-icon icon="contact_mail"></b-icon> Kunde</h2>

      <customer-find
        :customer="customer"
        v-if="!customerSelected"
        class="panel-block"
        @customer-selection-changed="customerSelectionChanged"
      />

      <customer-field
        :customer="customer"
        v-if="customerSelected"
        :changeLink="true"
        class="panel-block"
        @customer-selection-changed="customerSelectionChanged"
      />
    </div>

    <div id="invoicepositions" v-if="customerSelected" class="panel">
      <h2 class="panel-heading is-4">
        <b-icon icon="bottles"></b-icon> Rechnungspositionen
      </h2>
      <invoice-position
        class="panel-block"
        id="ip.key"
        v-for="(ip, index) in invoicePositions"
        :key="ip.index"
        :ip="ip"
        :invoicePositionIndex="index"
        :inputmode="true"
        :vatTypeForWine="vatTypeForWine"
        :consignmentmode="false"
        :invoiceType="invoiceType"
        @position-delete="positionDelete"
        @position-data-update="positionDataUpdate"
        @set-vat-type-for-wine="setVatTypeForWine"
        @get-ups-rate="getUpsRate"
      />
      <button
        @click="addInvoicePosition()"
        v-if="customerSelected"
        class="button"
        style="margin: 12px"
      >
        Rechnungsposition hinzufügen
      </button>

      <div v-if="summaries.count_wines >= 1" class="columns summary">
        <div class="column is-10">
          Summe Artikel (Gewinn:
          <span :class="getProfitCssClass()">{{ roundVal(summaries.sum_profit) }}€</span>)
        </div>
        <div class="column is-2 has-text-right">{{ roundVal(summaries.sum_wines) }}€</div>
      </div>

      <div v-if="summaries.count_misc >= 1" class="columns summary">
        <div class="column is-10">Summe Sonstiges</div>
        <div class="column is-2 has-text-right">{{ roundVal(summaries.sum_misc) }}€</div>
      </div>
      <div v-if="summaries.vat_absolute > 0" class="columns summary">
        <div class="column is-10">
          {{ this.$store.getters.config.vat }}% Mwst. auf
          {{ roundVal(summaries.sum_misc + summaries.sum_wines_for_vat) }}€
        </div>
        <div class="column is-2 has-text-right">{{ summaries.vat_absolute }}€</div>
      </div>
      <div v-if="summaries.total > 0" class="columns total">
        <div class="column is-10">Total</div>
        <div class="column is-2 has-text-right">{{ roundVal(summaries.total) }}€</div>
      </div>
    </div>

    <div class="panel-block">
      <router-link to="/invoices/index" custom v-slot="{ navigate }" type="button">
        <b-button @click="navigate" role="link">Zurück zur Übersicht</b-button>
      </router-link>
      &nbsp;
      <b-button
        @click="saveInvoice()"
        v-if="customerSelected && invoicePositions.length >= 1"
        class="button"
        type="is-info"
        icon-left="cloud_upload"
        native-type="submit"
      >
        Speichern
      </b-button>
    </div>

    <b-loading
      :is-full-page="false"
      :active.sync="isLoading"
      :can-cancel="false"
    ></b-loading>
  </div>
</template>

<script>
import CustomerField from "../_helpers/customerfield.vue";
import CustomerFind from "../_helpers/customerfind.vue";
import InvoicePosition from "../_helpers/invoiceposition.vue";

export default {
  name: "invoiceForm",
  components: {
    CustomerField,
    CustomerFind,
    InvoicePosition,
  },

  data() {
    return {
      invoiceType: "invoice",

      calculateWithVat: 1,
      explanationForVat: "",
      auctionList: [],
      aftersaleList: [],
      customer: {},
      summaries: {
        sum_wines: 0,
        sum_wines_for_vat: 0,
        sum_profit: 0,
        count_wines: 0,
        count_misc: 0,
        sum_misc: 0,
        vat_absolute: 0,
        total: 0,
      },
      customerSelected: false,
      noOfInvoicePositions: 0,
      invoicePositions: [],
      isLoading: false,
      defaultShippingCost: 0,
      vatTypeForWine: "all",
    };
  },

  mounted: function () {
    this.$store.commit("setHeadline", {
      subheadline: "Neue Rechnung/Gutschrift anlegen",
    });
  },
  methods: {
    // You have to install and import debounce to use it,
    // it's not mandatory though.

    addInvoicePosition() {
      this.noOfInvoicePositions++;

      this.invoicePositions.push({
        index: this.noOfInvoicePositions,
        key: "invoiceposition" + this.noOfInvoicePositions,
        title: "",
        type: null,
        article_id: null,
        stockarticle_id: null,
        price: 0,
        vat: 0,
        profit: 0,
        bottles: 0,
        updateCounter: 0,
        vatTypeForWine: this.vatTypeForWine,
        from_private: 0,
      });
    },

    customerSelectionChanged(customer) {
      if (typeof customer == "object") {
        this.customer = customer;
        this.customerSelected = true;

        this.noOfInvoicePositions = 0;
        this.invoicePositions = [];
        this.checkVatStatus();
        this.addInvoicePosition();
      } else {
        this.customer = {};
        this.customerSelected = false;
      }
    },

    checkVatStatus() {
      var expl = "";
      if (this.customer.country.vat_rule == 1) {
        this.calculateWithVat = 1;
        this.explanationForVat = "Zielland Deutschland";
      } else if (this.customer.country.vat_rule == 2) {
        if (this.customer.vat_id.length >= 8) {
          this.calculateWithVat = 0;
          this.explanationForVat = "Zielland EU, VAT-ID: " + this.customer.vat_id;
        } else {
          this.calculateWithVat = 1;
          this.explanationForVat = "Zielland EU, keine VAT-ID";
        }
      } else if (this.customer.country.vat_rule == 0) {
        this.calculateWithVat = 0;
        this.explanationForVat = "Zielland außerhalb EU";
      }
    },

    async saveInvoice() {
      this.isLoading = true;
      var t = this;
      t.$http
        .post("/invoices/save", {
          type: "post",
          customer: t.customer,
          invoicePositions: t.invoicePositions,
          summaries: t.summaries,
          invoiceType: t.invoiceType,
          calculateWithVat: t.calculateWithVat,
        })
        .then(function (resp) {
          t.isLoading = false;
          //Daten in Eltern setzen
          t.$parent.consignment = resp.data.data;
          //Auf Ansicht umleiten
          t.$router.push({ path: "/invoices/view/" + resp.data.data.id });
          return true;
        })
        .catch(function (error) {
          //console.log('Shithappens')
          if (
            typeof error.response != "undefined" &&
            typeof error.response.data.message != "undefined"
          ) {
            t.error = error.response.data.message;
          } else {
            t.error = "Server nicht erreichbar. Bitte versuchen Sie es erneut.";
          }
          t.isLoading = false;
          return false;
        });
    },
    positionDelete(ipKey) {
      //console.log("Lösche " + ipKey);
      for (var i = 0; i < this.invoicePositions.length; i++) {
        if (this.invoicePositions[i].key == ipKey) {
          //console.log("Lösche2 " + ipKey);
          this.invoicePositions.splice(i, 1);
          break;
        }
      }
      this.setVatTypeForWine();
    },
    positionDataUpdate(index, data) {
      var t = this;
      //console.log("Ändere index " + index + " mit daten ")
      for (var i = 0; i < t.invoicePositions.length; i++) {
        if (t.invoicePositions[i].index == index) {
          t.invoicePositions[i] = data;
          break;
        }
      }
      t.invoicePositions[i].updateCounter++;
      //console.log(data)
    },
    recalcInvoice: function () {
      //Berechne neu
      var tip = this.invoicePositions;
      var sum_wines = 0;
      var sum_wines_for_vat = 0;
      var sum_misc = 0;
      var sum_profit = 0;
      var count_wines = 0;
      var count_misc = 0;
      var deletePositions = [];

      for (var i = 0; i < tip.length; i++) {
        if (tip[i].type == "wine") {
          count_wines++;
          sum_wines += parseFloat(tip[i].price);

          if (tip[i].from_private == 0) {
            sum_wines_for_vat += parseFloat(tip[i].price);
          }
          if (typeof tip[i].profit != "undefined" && typeof tip[i].profit != null) {
            //console.log('Fügr profit hinzu '+ tip[i].profit)
            sum_profit += tip[i].profit;
          }
        } else if (tip[i].type == "credit") {
          count_wines++;
          sum_wines += parseFloat(tip[i].price);
        } else if (tip[i].type == "misc" || tip[i].type == "ship") {
          count_misc++;
          sum_misc += parseFloat(tip[i].price);
        }
      }

      for (var i = 0; deletePositions.length; i++) {}

      this.summaries.sum_wines = sum_wines;
      this.summaries.sum_wines_for_vat = sum_wines_for_vat;
      this.summaries.sum_profit = sum_profit;
      this.summaries.count_wines = count_wines;
      this.summaries.count_misc = count_misc;
      this.summaries.sum_misc = parseFloat(this.roundVal(sum_misc));
      if (this.calculateWithVat == 1) {
        this.summaries.vat_absolute = this.roundVal(
          ((sum_wines_for_vat + sum_misc) * this.$store.getters.config.vat) / 100
        );
      } else {
        this.summaries.vat_absolute = 0;
      }

      this.summaries.total =
        parseFloat(this.summaries.sum_wines) +
        parseFloat(this.summaries.sum_misc) +
        parseFloat(this.summaries.vat_absolute);
    },
    roundVal: function (val, digits = 2) {
      return (Math.round(val * 100) / 100).toFixed(digits);
    },
    getProfitCssClass() {
      if (this.summaries.sum_profit >= 0.01) {
        return "has-text-primary-dark";
      }
      return "has-text-danger-dark";
    },
    setVatTypeForWine() {
      //console.log('setze Vat type');
      var vatTypeForWine = this.vatTypeForWine;
      for (var i = 0; i < this.invoicePositions.length; i++) {
        //console.log('Position '+i)
        if (this.invoicePositions[i].type != "wine") {
          continue;
        }
        //console.log('from_private '+this.invoicePositions[i].from_private);
        if (this.invoicePositions[i].from_private == 0) {
          if (this.vatTypeForWine == "all") {
          } else if (this.vatTypeForWine != "normal") {
            //console.log('Vat-Konflikt 1');
          }
        } else if (this.invoicePositions[i].from_private == 1) {
          if (this.vatTypeForWine == "all") {
            vatTypeForWine = "diff";
            //console.log('setze diff');
          } else if (this.vatTypeForWine != "diff") {
            //console.log('Vat-Konflikt 2');
          }
        }
      }
      //console.log('final setze '+vatTypeForWine);
      this.vatTypeForWine = vatTypeForWine;
    },
    getUpsRate(id) {
      var t = this;
      t.$http
        .post("/invoices/get-ups-rate", {
          customer: t.customer,
          invoicePositions: t.invoicePositions,
        })
        .then(function (resp) {
          //console.log('Rate '+resp.data.data.rate)
          if (resp.data.code == 1 && typeof resp.data.error == "undefined") {
            t.$root.$emit("set-ups-rate", id, resp.data.data.rate);
            if (resp.data.data.alerts != false)
            {
              t.$buefy.toast.open({
              message: resp.data.data.alerts.join('<br>'),
              type: "is-warning",
              duration: 12000
            })
            }
          } else {
            t.$buefy.toast.open({
              message:
                "Konte UPS-Versandkosten nicht ermitteln" +
                (typeof resp.data.error != "undefined"
                  ? "(" + resp.data.error + ")"
                  : "") +
                ".",
              type: "is-danger",
              duration: 12000,
            });
            t.$root.$emit("set-ups-rate", id, 0);
          }

          return true;
        })
        .catch(function (error) {
          //console.log('Shithappens')
          if (
            typeof error.response != "undefined" &&
            typeof error.response.data.message != "undefined"
          ) {
            t.error = error.response.data.message;
          } else {
            t.error = "Server nicht erreichbar. Bitte versuchen Sie es erneut.";
          }
          console.log(error);
          t.$root.$emit("set-ups-rate", id, 0);
          return false;
        });
    },
  },
};
</script>
<style>
div.columns.summary {
  margin-left: 15px;
  margin-right: 15px;
  border-bottom: 1px solid #999999;
}
div.columns.total {
  margin-left: 15px;
  margin-right: 15px;
  font-size: 1.1rem;
  font-weight: 700;
}
</style>
